<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.technicians" action-menu :back-route="goToHome"/>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="users"
                :loading="loading"
                :search="search"
            >
              <template v-slot:top>
                <v-container class="pb-10" fluid>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="lang.search"
                          clearable
                          single-line
                          hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.action="{ item }">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                          :disabled="loading"
                          v-model="item.role"
                          color="primary"
                          :items="itemsSelect"
                          item-value="role"
                          item-text="description"
                          @change="changeRole(item)"/>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToHome} from '@/lib/redirects';
import {mapState, mapActions} from 'vuex';
import {getTechnician, putTechnicianRole} from '@/lib/backend/technician';

export default {
  name: 'Technicians',
  components: {AppNavBar},
  data() {
    return {
      goToHome: goToHome,
      search: '',
      itemsSelect: [{
        role: 'user',
        description: this.$lang.none
      }, {
        role: 'technician',
        description: this.$lang.technician
      }],
      loading: false,
      users: [],
      headers: [
        {text: this.$lang.email, value: 'email'},
        {text: this.$lang.name, value: 'name'},
        {text: this.$lang.role, value: 'action', sortable: false}
      ]
    }
  },
  mounted() {
    this.loadTechnicians();
  },
  methods: {
    ...mapActions('alert', ['showAlert']),
    async loadTechnicians() {
      this.loading = true;
      const response = await getTechnician();
      if (response) {
        if (response.status === 200) {
          this.users = response.data;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async changeRole(user) {
      this.loading = true;
      const response = await putTechnicianRole(user);
      if (response) {
        if (response.status === 200) {
          this.showAlert({message: this.lang.saved, timeout: 1000, color: 'success'});
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapState({
      user: state => state.config.user
    }),
    lang() {
      return this.$lang;
    }
  }
}
</script>
