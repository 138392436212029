<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="title" action-menu :back-route="goToServiceOrders"/>

    <dialog-form v-model="dialogPaste" :dialog-label="lang.pasteCoordinates" @ok="pasteCoordinates">
      <v-text-field
          slot="fields"
          ref="coords"
          @keyup.enter="enterCoords"
          v-model="pasteUrl"
          :label="lang.fullLatitudeLongitude"
          validate-on-blur
      />
    </dialog-form>

    <v-main class="fill-height">
      <loader :loading="loading">
        <v-container slot="content">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <div><span>{{ lang.status }}</span>: <span class="caption">{{ statusLabel }}</span></div>
                  <div v-if="isDone && serviceOrder.start_time"><span>{{ lang.timeStart }}</span>: <span
                      class="caption">{{ serviceOrder.start_time | formatDate }}</span></div>
                  <div v-if="isDone && serviceOrder.end_time"><span>{{ lang.timeEnd }}</span>: <span
                      class="caption">{{ serviceOrder.end_time | formatDate }}</span></div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-form :readonly="readOnly">
                <v-expansion-panels v-model="panels" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      {{ lang.osInfo }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-select v-model="serviceOrder.type" :label="lang.osType"
                                      :items="types" item-text="desc"
                                      :disabled="readOnly"
                                      item-value="id"/>
                          </v-col>
                          <v-col cols="12">
                            <v-select v-model="serviceOrder.technician_1" :label="lang.technician1"
                                      :items="technicians" item-text="name"
                                      :disabled="readOnly"
                                      :clearable="!readOnly"
                                      item-value="id"/>
                            <v-select v-model="serviceOrder.technician_2" :label="lang.technician2"
                                      :items="technicians" item-text="name"
                                      :disabled="readOnly"
                                      :clearable="!readOnly"
                                      item-value="id"/>
                          </v-col>
                          <v-col cols="12">
                            <v-select v-model="serviceOrder.company"
                                      :label="lang.company"
                                      :items="companies" item-text="name"
                                      :disabled="readOnly"
                                      :clearable="!readOnly"
                                      item-value="id"/>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                v-model="serviceOrder.client_name"
                                :label="lang.clientName"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                :maxLength="255"
                                :counter="255"
                                validate-on-blur
                                required
                            />
                            <v-text-field
                                v-model="serviceOrder.client_number_id"
                                :label="lang.clientNumberId"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                :maxLength="100"
                                :counter="100"
                                validate-on-blur
                                required
                            />
                            <v-text-field
                                type="tel"
                                v-model="serviceOrder.client_phone"
                                :label="lang.phone"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                :maxLength="20"
                                :counter="20"
                                validate-on-blur
                                required
                            />
                            <v-text-field
                                v-model="serviceOrder.client_address"
                                :label="lang.address"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                :maxLength="255"
                                :counter="255"
                                validate-on-blur
                                required
                            />
                            <v-text-field
                                :disabled="readOnly"
                                v-model.number="serviceOrder.latitude"
                                :label="lang.latitudeClient"
                                required
                            />
                            <v-text-field
                                :disabled="readOnly"
                                v-model.number="serviceOrder.longitude"
                                :label="lang.longitudeClient"
                                required
                            />
                            <v-btn v-if="!readOnly" text small color="secondary" @click="openPasteCoordinates">
                              {{ lang.pasteCoordinates }}
                            </v-btn>
                            <v-btn target="_blank" :href="googleMapsClientLink" text small color="secondary">
                              {{ lang.googleMaps }}
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-card flat>
                              <v-card-title class="subtitle-1">{{ lang.schedule }}</v-card-title>
                              <v-card-text>
                                <date-time-picker v-model="serviceOrder.schedule" :read-only="readOnly"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="isDone">
                    <v-expansion-panel-header>
                      {{ lang.tasks }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                                readonly
                                v-model.number="serviceOrder.drop_start_length"
                                :label="lang.dropStartLength"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                required
                            />
                            <v-text-field
                                readonly
                                v-model.number="serviceOrder.drop_end_length"
                                :label="lang.dropEndLength"
                                :disabled="readOnly"
                                :clearable="!readOnly"
                                required
                            />
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                readonly
                                disabled
                                v-model.number="serviceOrder.latitude_cto"
                                :label="lang.ctoLatitude"
                                required
                            />
                            <v-text-field
                                readonly
                                disabled
                                v-model.number="serviceOrder.longitude_cto"
                                :label="lang.ctoLongitude"
                                required
                            />
                            <v-btn target="_blank" :href="googleMapsCtoLink" text small color="secondary">
                              {{ lang.googleMaps }}
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                                readonly
                                disabled
                                v-model="serviceOrder.tag_cto"
                                :label="lang.tagCto"
                                required
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-card>
                              <v-card-title class="caption">{{ lang.powerDropCtoPortPicture }}</v-card-title>
                              <v-card-text>
                                <dynamic-picture :src-id="serviceOrder.id"
                                                 :img="serviceOrder.power_drop_cto_port_picture"
                                                 name="power-drop-cto-port-picture"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card>
                              <v-card-title class="caption">{{ lang.accommodationCtoPicture }}</v-card-title>
                              <v-card-text>
                                <dynamic-picture :src-id="serviceOrder.id" :img="serviceOrder.accommodation_cto_picture"
                                                 name="accommodation-cto-picture"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card>
                              <v-card-title class="caption">{{ lang.powerPowerMeterPicture }}</v-card-title>
                              <v-card-text>
                                <dynamic-picture :src-id="serviceOrder.id" :img="serviceOrder.power_power_meter_picture"
                                                 name="power-power-meter-picture"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card>
                              <v-card-title class="caption">{{ lang.equipmentAccommodationPicture }}</v-card-title>
                              <v-card-text>
                                <dynamic-picture :src-id="serviceOrder.id"
                                                 :img="serviceOrder.equipment_accommodation_picture"
                                                 name="equipment-accommodation-picture"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                          <v-col cols="4">
                            <v-card>
                              <v-card-title class="caption">{{ lang.contractPicture }}</v-card-title>
                              <v-card-text>
                                <dynamic-picture :src-id="serviceOrder.id" :img="serviceOrder.contract_picture"
                                                 name="contract-picture"/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-if="anyNotes">
                          <v-col cols="12">
                            <v-card>
                              <v-card-title class="subtitle-1">{{ lang.notes }}</v-card-title>
                              <v-card-text>
                                <v-textarea v-if="serviceOrder.supervisor_notes" v-model="serviceOrder.supervisor_notes"
                                            color="primary"
                                            :label="lang.supervisor"
                                            disabled
                                            readonly/>
                                <v-textarea v-if="serviceOrder.technician_1_notes"
                                            v-model="serviceOrder.technician_1_notes"
                                            color="primary"
                                            :label="lang.technician1" readonly disabled/>
                                <v-textarea v-if="serviceOrder.technician_2_notes"
                                            v-model="serviceOrder.technician_2_notes"
                                            color="primary"
                                            :label="lang.technician2" readonly disabled/>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-speed-dial v-if="isWaiting" v-model="fab" fab fixed bottom right open-on-hover>
                <template v-slot:activator>
                  <v-btn v-model="fab" color="primary" dark fab>
                    <v-icon v-if="fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-horizontal
                    </v-icon>
                  </v-btn>
                </template>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab dark small color="primary" @click="save">
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ lang.saveOs }}</span>
                </v-tooltip>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" fab dark small color="secondary" @click="openTasksConfirm">
                      <v-icon>mdi-file-tree</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ lang.openTasks }}</span>
                </v-tooltip>
              </v-speed-dial>
              <v-tooltip v-else-if="isCurrent && serviceOrder.supervisor_id && serviceOrder.supervisor_id === user.id"
                         top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mr-5 mb-5" v-bind="attrs" v-on="on" color="secondary" bottom right fixed dark fab
                         @click="openTasksConfirm">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ lang.goToTasks }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </loader>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToHome, goToServiceOrders, goToTasksSupervisor} from '@/lib/redirects';
import Loader from '@/components/Loader';
import {
  getGoogleMapsLink,
  getServiceOrderStatus,
  isWaitingServiceOrder,
  isCurrentServiceOrder,
  isDoneServiceOrder, formatDate
} from '@/lib/util';
import {getServiceOrder, putServiceOrder} from '@/lib/backend/serviceOrders';
import {mapActions, mapState} from 'vuex';
import {postTasks} from '@/lib/backend/task';
import DynamicPicture from '@/components/DynamicPicture';
import DialogForm from '@/components/DialogForm';
import DateTimePicker from '@/components/DateTimePicker';

export default {
  name: 'ServiceOrder',
  components: {DateTimePicker, DialogForm, DynamicPicture, Loader, AppNavBar},
  data() {
    return {
      dialogPaste: false,
      pasteUrl: '',
      panels: [0, 1],
      loading: true,
      fab: false,
      goToServiceOrders: goToServiceOrders,
      serviceOrder: {
        status: 0,
        type: null,
        company: null,
        supervisor_id: null,
        technician_1: null,
        technician_2: null,
        client_address: null,
        client_name: null,
        client_number_id: null,
        client_phone: null,
        latitude: null,
        longitude: null,
        supervisor_notes: null,
        technician_1_notes: null,
        technician_2_notes: null,
        drop_start_length: null,
        drop_end_length: null,
        latitude_cto: null,
        longitude_cto: null,
        tag_cto: null,
        schedule: null,
        power_drop_cto_port_picture: false,
        accommodation_cto_picture: false,
        power_power_meter_picture: false,
        equipment_accommodation_picture: false,
        contract_picture: false,
        start_time: null,
        end_time: null
      },
      technicians: [],
      companies: [],
      types: [
        {
          id: 0,
          desc: this.$lang.osTypeInstallation
        },
        {
          id: 1,
          desc: this.$lang.osTypeMaintenance
        },
        {
          id: 2,
          desc: this.$lang.osTypeTechnicalVisit
        }
      ]
    }
  },
  filters: {
    formatDate: formatDate
  },
  mounted() {
    this.loadServiceOrder();
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadServiceOrder() {
      if (this.$route.params.id) {
        try {
          const id = parseInt(this.$route.params.id);
          if (id > 0) {
            const response = await getServiceOrder(id);
            if (response) {
              if (response.status === 200) {
                this.companies = response.data.companies;
                this.technicians = response.data.technicians;
                this.serviceOrder = response.data.serviceOrder;
                this.loading = false;
                return;
              }
            } else {
              return;
            }
          }
        } catch (e) {
          //
        }
      }

      this.showAlert({
        message: this.lang.serviceOrderIdError
      })
      goToHome();
    },
    async doSave(showSaved = false) {
      const saveServiceOrder = {
        id: this.serviceOrder.id,
        type: this.serviceOrder.type,
        company: this.serviceOrder.company || null,
        technician_1: this.serviceOrder.technician_1 || null,
        technician_2: this.serviceOrder.technician_2 || null,
        client_address: this.serviceOrder.client_address || null,
        client_name: this.serviceOrder.client_name || null,
        client_number_id: this.serviceOrder.client_number_id || null,
        client_phone: this.serviceOrder.client_phone || null,
        latitude: this.serviceOrder.latitude || null,
        longitude: this.serviceOrder.longitude || null,
        schedule: this.serviceOrder.schedule
      };
      const response = await putServiceOrder(saveServiceOrder);
      if (response) {
        if (response.status === 200) {
          if (showSaved) {
            this.showAlert({
              message: this.lang.saved,
              color: 'success',
              timeout: 2000
            })
          }
          return true;
        } else {
          this.showAlert({
            message: response.message
          })
        }
      }
      return false;
    },
    async save() {
      if (this.serviceOrder.technician_2) {
        if (this.serviceOrder.technician_1 === this.serviceOrder.technician_2) {
          this.showAlert({message: this.lang.duplicatedTechnicians});
          return;
        }
      }
      this.loading = true;
      await this.doSave(true);
      this.loading = false;
    },
    openTasksConfirm() {
      if (!this.companies.length) {
        this.showAlert({message: this.lang.warningEmptyCompanies});
        return;
      }
      if (!this.technicians.length) {
        this.showAlert({message: this.lang.warningEmptyTechs});
        return;
      }
      if (!this.serviceOrder.technician_1) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.technician1)});
        return;
      }
      if (this.serviceOrder.technician_2) {
        if (this.serviceOrder.technician_1 === this.serviceOrder.technician_2) {
          this.showAlert({message: this.lang.duplicatedTechnicians});
          return;
        }
      }
      if (!this.serviceOrder.company) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.company)});
        return;
      }
      if (!this.serviceOrder.client_name) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.clientName)});
        return;
      }
      if (!this.serviceOrder.client_number_id) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.clientNumberId)});
        return;
      }
      if (!this.serviceOrder.client_phone) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.clientPhone)});
        return;
      }
      if (!this.serviceOrder.client_address) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.clientAddress)});
        return;
      }
      if (!this.serviceOrder.latitude) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.latitudeClient)});
        return;
      }
      if (!this.serviceOrder.longitude) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.longitudeClient)});
        return;
      }
      if (!this.serviceOrder.schedule) {
        this.showAlert({message: this.lang.customFieldRequired(this.lang.schedule)});
        return;
      }
      if (this.isCurrent) {
        this.openTasks();
      } else {
        this.showConfirmation({
          title: this.lang.confirmQuestion,
          text: this.lang.openTasksWarning,
          action: this.openTasks
        });
      }
    },
    async openTasks() {
      this.loading = true;
      if (!this.isCurrent) {
        const save = await this.doSave();
        if (!save) {
          return;
        }
      }
      const response = await postTasks({
        service_order_id: this.serviceOrder.id
      });
      if (response) {
        if (response.status === 200) {
          goToTasksSupervisor(response.data);
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    openPasteCoordinates() {
      this.pasteUrl = '';
      this.dialogPaste = true;
      setTimeout(() => {
        this.$refs.coords.focus();
      }, 500);
    },
    pasteCoordinates() {
      if (this.pasteUrl) {
        const nums = this.pasteUrl.split(',');
        if (nums.length === 2) {
          try {
            const lat = parseFloat(nums[0].trim());
            const lon = parseFloat(nums[1].trim());
            if (!isNaN(lat) && !isNaN(lon)) {
              this.serviceOrder.latitude = lat;
              this.serviceOrder.longitude = lon;
              this.dialogPaste = false;
              return;
            }
          } catch (e) {
            //
          }
        }
      }
      this.showAlert({message: this.lang.errorPasteCoordinates});
      this.dialogPaste = false;
    },
    enterCoords() {
      this.pasteCoordinates();
    }
  },
  computed: {
    ...mapState({
      user: state => state.config.user
    }),
    readOnly() {
      return !this.isWaiting;
    },
    lang() {
      return this.$lang;
    },
    title() {
      if (!this.loading) {
        return this.lang.customServiceOrder(this.serviceOrder.id);
      } else {
        return this.lang.loading;
      }
    },
    googleMapsClientLink() {
      return getGoogleMapsLink(this.serviceOrder.latitude, this.serviceOrder.longitude)
    },
    googleMapsCtoLink() {
      return getGoogleMapsLink(this.serviceOrder.latitude_cto, this.serviceOrder.longitude_cto);
    },
    statusLabel() {
      return getServiceOrderStatus(this.serviceOrder.status);
    },
    isWaiting() {
      return isWaitingServiceOrder(this.serviceOrder.status);
    },
    isDone() {
      return isDoneServiceOrder(this.serviceOrder.status);
    },
    isCurrent() {
      return isCurrentServiceOrder(this.serviceOrder.status);
    },
    anyNotes() {
      return this.serviceOrder.supervisor_notes || this.serviceOrder.technician_1_notes || this.serviceOrder.technician_2_notes;
    }
  }
}
</script>
