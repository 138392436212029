export const MIN_PASSWORD_LENGTH = 6;
export const MAX_EMAIL_LENGTH = 320;
export const MAX_PASSWORD_LENGTH = 100;
export const MAX_NAME_USER_LENGTH = 255;
export const validEmail = email => {
    if (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    return false;
};
export const validCnpj = cnpjMask => {
    if (!cnpjMask || cnpjMask.length < 14) return false;

    const cnpj = cnpjMask.onlyNumbers();

    if (cnpj.length !== 14 || cnpj === cnpj[0].repeat(14)) return false;

    let size = cnpj.length - 2;
    let numbers = cnpj.substring(0, size);
    let digits = cnpj.substring(size);
    let sum = 0;
    let pos = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    if (result !== parseInt(digits.charAt(0))) return false;

    size = size + 1;
    numbers = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
    }
    result = sum % 11 < 2 ? 0 : 11 - sum % 11;
    return result === parseInt(digits.charAt(1));
}
export const validOnlyNumbers = str => {
    if (str) {
        const re = /^\d+$/;
        return re.test(String(str).toLowerCase());
    }
    return false;
}
export const validOnlyDecimal = str => {
    if (str) {
        const re = /^[+-]?(?=.?\d)\d*(\.\d{0,50})?$/gm;
        return re.test(String(str).toLowerCase());
    }
    return false;
}
export const validOnlyDecimalPositive = str => {
    if (str) {
        const re = /^(?=.?\d)\d*(\.\d{0,50})?$/gm;
        return re.test(String(str).toLowerCase());
    }
    return false;
}
