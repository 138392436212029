var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('app-nav-bar',{attrs:{"title-bar":_vm.lang.tasks,"action-menu":"","back-route":_vm.goToHome}}),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersSeeMore,"items":_vm.itemsSeeMore,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.key",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(item.key))])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(_vm._s(_vm.lang.close))])],1)],1)],1),_c('v-main',{staticClass:"fill-height"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tasksTechnicians,"loading":_vm.loading,"search":_vm.search,"disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.schedule",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.schedule))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.location(item))?_c('v-btn',{attrs:{"href":_vm.location(item),"target":"_blank","text":""}},[_c('v-icon',[_vm._v("mdi-google-maps")])],1):_vm._e(),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.seeMore(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.action2",fn:function(ref){
var item = ref.item;
return [(item.tasks)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToTasks(item)}}},[_vm._v(" "+_vm._s(_vm.lang.goToTasks)+" "),_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }