<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card :disabled="loading" :loading="loading" outlined>
          <v-stepper v-model="stepper">
            <v-stepper-items>
              <v-stepper-content class="pa-0" step="1">
                <v-card>
                  <v-container>
                    <v-row class="text-center">
                      <v-col cols="12">
                        <v-avatar tile size="60" width="90">
                          <v-img src="../assets/logo.png" alt="logo"/>
                        </v-avatar>
                      </v-col>
                      <v-col class="title" cols="12">
                        {{ lang.makeLogin }}
                      </v-col>
                      <v-col cols="12">
                        <v-form ref="formLogin">
                          <v-text-field v-model="email"
                                        ref="email"
                                        type="email"
                                        name="email"
                                        :label="lang.email"
                                        clearable
                                        required
                                        outlined
                                        :maxLength="maxEmailLength"
                                        :counter="maxEmailLength"
                                        @keyup.enter="enterEmail"
                                        @input="clearCredentialsInputs"
                                        :error-messages="errorsMessagesEmail"
                          />
                          <v-text-field v-model="password"
                                        ref="password"
                                        name="password"
                                        :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="visiblePassword ? 'text' : 'password'"
                                        :label="lang.password"
                                        clearable
                                        required
                                        outlined
                                        :maxLength="maxPasswordLength"
                                        :counter="maxPasswordLength"
                                        @keyup.enter="enterPassword"
                                        @input="errorsMessagesPassword = []"
                                        @click:append="visiblePassword = !visiblePassword"
                                        :error-messages="errorsMessagesPassword"
                          />
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-btn class="primary--text" small text @click="changeRegister">{{ lang.createAccount }}</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="primary" small @click="authenticate">{{ lang.signIn }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-stepper-content>

              <v-stepper-content class="pa-0" step="2">
                <v-card>
                  <v-container>
                    <v-row class="text-center">
                      <v-col cols="12">
                        <v-avatar tile size="60" width="90">
                          <v-img src="../assets/logo.png" alt="logo"/>
                        </v-avatar>
                      </v-col>
                      <v-col class="title" cols="12">
                        {{ lang.createAccount }}
                      </v-col>
                      <v-col cols="12">
                        <v-form ref="formRegister">
                          <v-text-field v-model="registerEmailLower"
                                        ref="registerEmail"
                                        type="email"
                                        name="email"
                                        :label="lang.email"
                                        clearable
                                        required
                                        outlined
                                        :counter="maxEmailLength"
                                        :maxLength="maxEmailLength"
                                        @keyup.enter="enterRegisterEmail"
                                        @input="errorsRegisterMessagesEmail = []"
                                        :error-messages="errorsRegisterMessagesEmail"
                          />
                          <v-text-field v-model="registerNameUpper"
                                        ref="registerName"
                                        name="name"
                                        :label="lang.name"
                                        clearable
                                        required
                                        outlined
                                        @keyup.enter="enterRegisterName"
                                        :maxLength="maxNameUserLength"
                                        :counter="maxNameUserLength"
                                        @input="errorsRegisterMessagesName = []"
                                        :error-messages="errorsRegisterMessagesName"
                          />
                          <v-text-field v-model="registerPassword"
                                        ref="registerPassword"
                                        :append-icon="registerVisiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="registerVisiblePassword ? 'text' : 'password'"
                                        :label="lang.password"
                                        clearable
                                        required
                                        outlined
                                        :maxLength="maxPasswordLength"
                                        :counter="maxPasswordLength"
                                        @keyup.enter="enterRegisterPassword"
                                        @click:append="registerVisiblePassword = !registerVisiblePassword"
                                        @input="errorsRegisterMessagesPassword = []"
                                        :error-messages="errorsRegisterMessagesPassword"
                          />
                          <v-text-field v-model="registerPasswordConfirm"
                                        ref="registerPasswordConfirm"
                                        :append-icon="registerVisiblePasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="registerVisiblePasswordConfirm ? 'text' : 'password'"
                                        :label="lang.confirmPassword"
                                        clearable
                                        required
                                        outlined
                                        :maxLength="maxPasswordLength"
                                        :counter="maxPasswordLength"
                                        @keyup.enter="enterRegisterPasswordConfirm"
                                        @click:append="registerVisiblePasswordConfirm = !registerVisiblePasswordConfirm"
                                        @input="errorsRegisterMessagesPasswordConfirm = []"
                                        :error-messages="errorsRegisterMessagesPasswordConfirm"
                          />
                        </v-form>
                      </v-col>
                      <v-col cols="6">
                        <v-btn class="primary--text" small text @click="changeLogin">{{ lang.makeLoginInstead }}</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="primary" small @click="register">{{ lang.createAccount }}</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {postLogin, postRegister} from '@/lib/backend/auth';
import {mapActions} from 'vuex';
import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_USER_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  validEmail
} from '@/lib/validation';
import {goToHome} from '@/lib/redirects';

export default {
  name: 'Login',
  data() {
    return {
      stepper: 1,
      errorsMessagesEmail: [],
      errorsMessagesPassword: [],
      loading: false,
      email: '',
      password: '',
      visiblePassword: false,
      registerEmail: '',
      registerName: '',
      registerPassword: '',
      registerPasswordConfirm: '',
      registerVisiblePassword: false,
      registerVisiblePasswordConfirm: false,
      errorsRegisterMessagesEmail: [],
      errorsRegisterMessagesName: [],
      errorsRegisterMessagesPassword: [],
      errorsRegisterMessagesPasswordConfirm: [],
      maxEmailLength: MAX_EMAIL_LENGTH,
      maxPasswordLength: MAX_PASSWORD_LENGTH,
      maxNameUserLength: MAX_NAME_USER_LENGTH
    }
  },
  mounted() {
    this.delayedEmailFocus();
  },
  computed: {
    registerNameUpper: {
      get: function () {
        return this.registerName.toUpperCase();
      },
      set: function (val) {
        this.registerName = val.toUpperCase();
      }
    },
    registerEmailLower: {
      get: function () {
        if (this.registerEmail) {
          return this.registerEmail.toLowerCase();
        }
        return this.registerEmail;
      },
      set: function (val) {
        if (val) {
          this.registerEmail = val.toLowerCase();
        }
        this.registerEmail = val;
      }
    },
    lang() {
      return this.$lang;
    }
  },
  methods: {
    ...mapActions('alert', ['showAlert']),
    ...mapActions('config', ['setConfig']),
    enterEmail() {
      this.$refs.password.focus();
    },
    enterPassword() {
      this.authenticate();
    },
    enterRegisterEmail() {
      this.$refs.registerName.focus();
    },
    enterRegisterName() {
      this.$refs.registerPassword.focus();
    },
    enterRegisterPassword() {
      this.$refs.registerPasswordConfirm.focus();
    },
    enterRegisterPasswordConfirm() {
      this.register();
    },
    customValidate() {
      if (!this.$refs.formLogin.validate()) {
        return false;
      }

      if (!this.email || !this.email.length) {
        this.errorsMessagesEmail = [this.lang.fieldRequired];
      } else if (!validEmail(this.email)) {
        this.errorsMessagesEmail = [this.lang.invalidEmail];
      }

      if (!this.password || !this.password.length) {
        this.errorsMessagesPassword = [this.lang.fieldRequired];
      } else if (this.password.length < MIN_PASSWORD_LENGTH) {
        this.errorsMessagesPassword = [this.lang.invalidPassword];
      }

      if (this.errorsMessagesEmail.length > 0) {
        this.$refs.email.focus();
        return false;
      }

      if (this.errorsMessagesPassword.length > 0) {
        this.$refs.password.focus();
        return false;
      }

      return true;
    },
    customRegisterValidate() {
      if (!this.$refs.formRegister.validate()) {
        return false;
      }

      if (!this.registerEmail || !this.registerEmail.length) {
        this.errorsRegisterMessagesEmail = [this.lang.fieldRequired];
      } else if (!validEmail(this.registerEmail)) {
        this.errorsRegisterMessagesEmail = [this.lang.invalidEmail];
      }

      if (!this.registerName || !this.registerName.length) {
        this.errorsRegisterMessagesName = [this.lang.fieldRequired];
      }

      if (!this.registerPassword || !this.registerPassword.length) {
        this.errorsRegisterMessagesPassword = [this.lang.fieldRequired];
      } else if (this.registerPassword.length < MIN_PASSWORD_LENGTH) {
        this.errorsRegisterMessagesPassword = [this.lang.customInvalidLengthPassword(MIN_PASSWORD_LENGTH)];
      }

      if (!this.registerPasswordConfirm || !this.registerPasswordConfirm.length) {
        this.errorsRegisterMessagesPasswordConfirm = [this.lang.fieldRequired];
      } else if (this.registerPassword !== this.registerPasswordConfirm) {
        this.errorsRegisterMessagesPasswordConfirm = [this.lang.notEqualConfirmPasswordNewPassword];
      }

      if (this.errorsRegisterMessagesEmail.length > 0) {
        this.$refs.registerEmail.focus();
        return false;
      }

      if (this.errorsRegisterMessagesName.length > 0) {
        this.$refs.registerName.focus();
        return false;
      }

      if (this.errorsRegisterMessagesPassword.length > 0) {
        this.$refs.registerPassword.focus();
        return false;
      }

      if (this.errorsRegisterMessagesPasswordConfirm.length > 0) {
        this.$refs.registerPasswordConfirm.focus();
        return false;
      }

      return true;
    },
    async authenticate() {
      if (this.customValidate()) {
        this.loading = true;
        const response = await postLogin({
          email: this.email,
          password: this.password,
          cookie: true
        });
        if (response) {
          if (response.status === 200) {
            this.setConfig(response.data.config);
            goToHome();
          } else {
            if (response.status === 401) {
              this.errorsMessagesPassword = [this.lang.invalidPassword];
              this.$refs.password.focus();
            } else if (response.status === 406) {
              this.errorsMessagesEmail = [this.lang.noAccountAssociated];
              this.$refs.email.focus();
            } else if (response.status === 429) {
              this.showAlert({
                message: this.lang.tooManyAttemptsTryLater
              });
            } else {
              this.showAlert({
                message: response.message,
                timeout: 0
              })
            }
            this.password = '';
          }
        }
        this.loading = false;
      }
    },
    changeRegister() {
      this.clearFields();
      this.stepper = 2;
    },
    async register() {
      if (this.customRegisterValidate()) {
        this.loading = true;
        const response = await postRegister({
          email: this.registerEmail.toLowerCase(),
          password: this.registerPassword,
          name: this.registerName.toUpperCase()
        });
        if (response) {
          if (response.status === 200) {
            this.clearFields();
            this.changeLogin();
            this.showAlert({
              message: this.lang.accountCreatedDoLogin,
              color: 'success'
            });
          } else {
            if (response.status === 400) {
              this.errorsRegisterMessagesEmail = [this.lang.invalidEmail];
            } else if (response.status === 409) {
              this.errorsRegisterMessagesEmail = [this.lang.accountAssociated];
            } else {
              this.showAlert({
                message: response.message
              })
            }
          }
        }
        this.loading = false;
      }
    },
    changeLogin() {
      this.clearFields();
      this.stepper = 1;
    },
    clearFields() {
      this.errorsMessagesEmail = [];
      this.errorsMessagesPassword = [];
      this.loading = false;
      this.email = '';
      this.password = '';
      this.visiblePassword = false;
      this.registerEmail = '';
      this.registerPassword = '';
      this.registerPasswordConfirm = '';
      this.registerName = '';
      this.registerVisiblePassword = false;
      this.registerVisiblePasswordConfirm = false;
      this.errorsRegisterMessagesEmail = [];
      this.errorsRegisterMessagesName = [];
      this.errorsRegisterMessagesPassword = [];
      this.errorsRegisterMessagesPasswordConfirm = [];
    },
    delayedEmailFocus() {
      const ctx = this;
      setTimeout(() => {
        ctx.$refs.email.focus();
      }, 100);
    },
    delayedRegisterEmailFocus() {
      const ctx = this;
      setTimeout(() => {
        ctx.$refs.registerEmail.focus();
      }, 100);
    },
    clearCredentialsInputs() {
      this.errorsMessagesEmail = [];
      this.errorsMessagesPassword = [];
    }
  },
  watch: {
    stepper(step) {
      if (step === 1) {
        this.delayedEmailFocus();
      } else if (step === 2) {
        this.delayedRegisterEmailFocus();
      }
    }
  }
}
</script>
