import {request, tkOptimizer} from '@/lib/backend/http';

export const getCompany = () => {
    return request(tkOptimizer().get, '/company');
};

export const postCompany = (company) => {
    return request(tkOptimizer().post, '/company', company);
};

export const putCompany = (company) => {
    return request(tkOptimizer().put, `/company/${company.id}`, company);
};

export const deleteCompany = (company) => {
    return request(tkOptimizer().delete, `/company/${company.id}`);
};

