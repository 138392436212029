import {tkOptimizer, request} from './http';

export const postLogin = (credentials) => {
    return request(tkOptimizer().post, '/auth/login', credentials, false);
};

export const postLogout = () => {
    return request(tkOptimizer().post, '/auth/logout', false);
};

export const postRegister = (user) => {
    return request(tkOptimizer().post, '/auth/register', user, false);
};
