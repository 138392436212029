<template>
  <v-app-bar :color="$vuetify.theme.dark ? '' : 'primary'" app dark>
    <v-app-bar-nav-icon v-if="backRoute" @click="backClick">
      <v-icon>mdi-arrow-left</v-icon>
    </v-app-bar-nav-icon>
    <v-toolbar-title>{{ titleBar }}
      <slot name="append-title-icon"></slot>
    </v-toolbar-title>
    <div v-if="actionMenu" class="flex-grow-1"/>
    <v-menu v-if="actionMenu" transition="slide-x-transition" :min-width="150" bottom left>
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, i) in actionMenuItems" :key="i" @click="item.click">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import {goToLogout} from '@/lib/redirects';

export default {
  name: 'AppNavBar',
  props: {
    titleBar: {
      type: String,
      required: false,
      default: ''
    },
    actionMenu: {
      type: Boolean,
      required: false,
      default: false
    },
    backRoute: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      actionMenuItems: [{
        title: this.$lang.logout,
        click: this.logout
      }]
    }
  },
  methods: {
    logout() {
      goToLogout();
    },
    backClick() {
      this.backRoute();
    }
  }
}
</script>
