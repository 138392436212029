<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6">
        <v-dialog v-model="modalDate"
                  :return-value.sync="schedule.date"
                  ref="dialogDate"
                  persistent
                  width="290px"
                  @update:return-value="updateValue"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :disabled="readOnly"
                :clearable="!readOnly"
                prepend-icon="mdi-calendar"
                :label="lang.date"
                :value="formattedDate"
                @click:clear="clearDate"
                readonly
                v-bind="attrs"
                v-on="on"
            />
          </template>
          <v-date-picker v-model="schedule.date">
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="modalDate = false"
            >
              {{ lang.cancel }}
            </v-btn>
            <v-btn text color="primary" @click="dateOk">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" lg="6">
        <v-dialog v-model="modalTime"
                  :return-value.sync="schedule.time"
                  ref="dialogTime"
                  persistent
                  width="290px"
                  @update:return-value="updateValue"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :disabled="readOnly"
                :clearable="!readOnly"
                prepend-icon="mdi-calendar"
                :label="lang.time"
                :value="schedule.time"
                @click:clear="clearTime"
                readonly
                v-bind="attrs"
                v-on="on"
            />
          </template>
          <v-time-picker v-model="schedule.time" format="24hr">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalTime = false">
              {{ lang.cancel }}
            </v-btn>
            <v-btn text color="primary" @click="timeOk">
              OK
            </v-btn>
          </v-time-picker>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    value: String,
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  name: 'DateTimePicker',
  data: () => ({
    modalDate: false,
    modalTime: false,
    schedule: {
      date: null,
      time: null
    }
  }),
  methods: {
    clearDate() {
      this.schedule.date = null;
      this.updateValue();
    },
    clearTime() {
      this.schedule.time = null
      this.updateValue();
    },
    updateValue() {
      if (this.schedule.date && this.schedule.time) {
        this.$emit('input', new Date(`${this.schedule.date} ${this.schedule.time}`).toISOString());
      } else {
        this.$emit('input', null);
      }
    },
    dateOk() {
      this.$refs.dialogDate.save(this.schedule.date);
      this.updateValue();
    },
    timeOk() {
      this.$refs.dialogTime.save(this.schedule.time)
      this.updateValue();
    },
    setValue(n) {
      if (n) {
        const datetime = new Date(n);
        this.schedule.date = moment(datetime).format('YYYY-MM-DD');
        this.schedule.time = moment(datetime).format('HH:mm');
      } else {
        this.schedule.date = null;
        this.schedule.time = null;
      }
    }
  },
  mounted() {
    this.setValue(this.value);
  },
  computed: {
    lang() {
      return this.$lang;
    },
    formattedDate() {
      if (this.schedule.date) {
        return moment(this.schedule.date).format('DD/MM/YYYY');
      } else {
        return null;
      }
    }
  },
  watch: {
    value(n, o) {
      if (n !== o) {
        this.setValue(n);
      }
    }
  }
}
</script>
