import Vue from 'vue';
import Router from 'vue-router';
import Login from '@/views/Login';
import Home from '@/views/Home';
import Logout from '@/views/Logout';
import Account from '@/views/Account';
import Technicians from '@/views/Technicians';
import ServiceOrders from '@/views/ServiceOrders';
import Companies from '@/views/Companies';
import ServiceOrder from '@/views/ServiceOrder';
import TasksSupervisor from '@/views/TasksSupervisor';
import TasksTechnicians from '@/views/TasksTechnicians';
import TasksTechnician from '@/views/TasksTechnician';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                roles: []
            }
        },
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                roles: []
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                roles: []
            }
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            meta: {
                roles: []
            }
        },
        {
            path: '/technicians',
            name: 'technicians',
            component: Technicians,
            meta: {
                roles: ['supervisor']
            }
        },
        {
            path: '/service-orders',
            name: 'serviceOrders',
            component: ServiceOrders,
            meta: {
                roles: ['supervisor']
            }
        },
        {
            path: '/service-order/:id',
            name: 'serviceOrder',
            component: ServiceOrder,
            meta: {
                roles: ['supervisor']
            }
        },
        {
            path: '/tasks/:id/supervisor',
            name: 'tasksSupervisor',
            component: TasksSupervisor,
            meta: {
                roles: ['supervisor']
            }
        },
        {
            path: '/tasks/technicians',
            name: 'tasksTechnicians',
            component: TasksTechnicians,
            meta: {
                roles: ['technician']
            }
        },
        {
            path: '/tasks/:id/:role',
            name: 'tasksTechnician',
            component: TasksTechnician,
            meta: {
                roles: ['technician']
            }
        },
        {
            path: '/companies',
            name: 'companies',
            component: Companies,
            meta: {
                roles: ['supervisor']
            }
        },
        {
            path: '*',
            redirect: '/',
            meta: {
                roles: []
            }
        }
    ]
})
