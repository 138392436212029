import {request, tkOptimizer} from '@/lib/backend/http';

export const getServiceOrders = (params) => {
    return request(tkOptimizer().get, `/service-order`, {params});
};

export const getServiceOrder = (id) => {
    return request(tkOptimizer().get, `/service-order/${id}`);
};

export const postServiceOrder = () => {
    return request(tkOptimizer().post, '/service-order');
};

export const putServiceOrder = (serviceOrder) => {
    return request(tkOptimizer().put, `/service-order/${serviceOrder.id}`, serviceOrder);
};

export const getServiceOrderImage = (serviceOrderId, image) => {
    return request(tkOptimizer().get, `/service-order/${serviceOrderId}/${image}`);
};
