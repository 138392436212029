import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import ptBr from './lang/ptbr';

Vue.config.productionTip = false;
Vue.prototype.$lang = ptBr;

Array.prototype.replace = function (item) {
    if (item && item.id) {
        const realItemArray = this.filter(p => p.id === item.id);
        const realItem = realItemArray.length ? realItemArray[0] : null;
        if (realItem) {
            const index = this.indexOf(realItem);
            if (index !== -1) {
                this.splice(index, 1, item);
            }
        }
    }
};

Array.prototype.remove = function (item) {
    if (item && item.id) {
        const realItemArray = this.filter(p => p.id === item.id);
        const realItem = realItemArray.length ? realItemArray[0] : null;
        if (realItem) {
            const index = this.indexOf(realItem);
            if (index !== -1) {
                this.splice(index, 1);
            }
        }
    }
};

Array.prototype.getId = function () {
    if (this.length > 0) {
        return this[this.length - 1].id + 1;
    } else {
        return 1;
    }
};

String.prototype.onlyNumbers = function () {
    return this.replace(/[^0-9]/g, '');
};

String.prototype.cnpjMask = function () {
    let cnpj = this;
    cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(\d)/, '$1/$2');
    cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, '$1-$2');
    return cnpj;
}

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
