<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.companies" action-menu :back-route="goToHome"/>

    <dialog-form v-model="dialog" :dialog-label="dialogLabel" :dialog-loading="dialogLoading"
                 @ok="createEditCompany">
      <v-container slot="fields">
        <v-row>
          <v-col cols="12">
            <v-text-field
                ref="companyName"
                v-model="companyNameUpper"
                :label="lang.name"
                :rules="companyNameRules"
                :maxLength="100"
                :counter="100"
                validate-on-blur
                required
            />
            <v-text-field
                ref="cnpj"
                type="tel"
                v-model="cnpj"
                v-mask="'##.###.###/####-##'"
                :label="lang.cnpj"
                :rules="cnpjNameRules"
                validate-on-blur
                required
            />
            <v-text-field
                ref="email"
                type="email"
                v-model="emailLower"
                :label="lang.email"
                :rules="emailRules"
                :maxLength="maxEmailLength"
                :counter="maxEmailLength"
                clearable
                validate-on-blur
                required
            />
            <v-text-field
                ref="phone"
                type="phone"
                v-model="phone"
                :label="lang.phone"
                :rules="phoneRules"
                clearable
                :maxLength="20"
                :counter="20"
                validate-on-blur
                required
            />
          </v-col>
        </v-row>
      </v-container>
    </dialog-form>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers"
                :items="companies"
                :loading="loading"
                :search="search"
            >
              <template v-slot:top>
                <v-container fluid class="pb-10">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          :label="lang.search"
                          clearable
                          single-line
                          hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:item.cnpj="{item}">
                {{ item.cnpj.cnpjMask() }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon class="mr-2" @click="openDialog(item)">mdi-pencil</v-icon>
                <v-icon @click="removeCompany(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-btn class="mr-5 mb-5" color="primary" fab fixed bottom right @click="openDialog(null)">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import {goToHome} from '@/lib/redirects';
import AppNavBar from '@/components/AppNavBar';
import {deleteCompany, getCompany, postCompany, putCompany} from '@/lib/backend/company';
import DialogForm from '@/components/DialogForm';
import {MAX_EMAIL_LENGTH, validCnpj, validEmail, validOnlyNumbers} from '@/lib/validation';
import {mapActions} from 'vuex';
import {mask} from 'vue-the-mask';

export default {
  name: 'Companies',
  components: {DialogForm, AppNavBar},
  directives: {mask},
  data() {
    return {
      maxEmailLength: MAX_EMAIL_LENGTH,
      dialog: false,
      dialogLoading: false,
      companyId: 0,
      companyName: '',
      cnpj: '',
      email: '',
      phone: '',
      goToHome: goToHome,
      search: '',
      loading: false,
      companies: [],
      headers: [
        {text: this.$lang.cnpj, value: 'cnpj'},
        {text: this.$lang.name, value: 'name'},
        {text: '', value: 'action', sortable: false}
      ],
      companyNameRules: [
        v => !!v || this.lang.fieldRequired
      ],
      cnpjNameRules: [
        (v) => validCnpj(v) || this.lang.invalidCnpj
      ],
      emailRules: [
        (v) => {
          if (!v) {
            return true;
          }
          if (validEmail(v)) {
            return true;
          }
          return this.lang.invalidEmail;
        }
      ],
      phoneRules: [
        (v) => {
          if (!v) {
            return true;
          }
          if (validOnlyNumbers(v)) {
            return true;
          }
          return this.lang.onlyNumbers;
        }
      ]
    }
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadCompanies() {
      this.loading = true;
      const response = await getCompany();
      if (response) {
        if (response.status === 200) {
          this.companies = response.data;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
    },
    async createEditCompany() {
      this.dialogLoading = true;
      const method = this.companyId === 0 ? postCompany : putCompany;
      const response = await method({
        id: this.companyId,
        cnpj: this.cnpj.onlyNumbers(),
        name: this.companyName.toUpperCase(),
        email: this.email ? this.email.toLowerCase() : null,
        phone: this.phone ? this.phone.toLowerCase() : null
      });
      if (response) {
        if (response.status === 200) {
          if (this.companyId) {
            this.companies.replace(response.data);
          } else {
            this.companies.push(response.data);
          }
          this.showAlert({message: this.lang.saved, timeout: 1000, color: 'success'});
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.dialogLoading = false;
      this.dialog = false;
    },
    openDialog(company) {
      if (company) {
        this.companyId = company.id;
        this.cnpj = company.cnpj ? company.cnpj.cnpjMask() : null;
        this.companyName = company.name;
        this.phone = company.phone;
        this.email = company.email;
      } else {
        this.companyId = 0;
        this.cnpj = '';
        this.email = '';
        this.companyName = '';
        this.phone = '';
      }

      this.dialog = true;
      const ctx = this;
      setTimeout(() => {
        ctx.$refs.companyName.focus();
      }, 100);
    },
    removeCompany(company) {
      this.showConfirmation({
        title: this.lang.confirmQuestion,
        text: this.lang.customDelete(company.name),
        action: async () => {
          this.loading = true;
          const response = await deleteCompany(company);
          if (response) {
            if (response.status === 200) {
              this.companies.remove(company)
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.loading = false;
        }
      });
    }
  },
  computed: {
    companyNameUpper: {
      get: function () {
        if (this.companyName) {
          return this.companyName.toUpperCase();
        }
        return this.companyName;
      },
      set: function (val) {
        if (val) {
          this.companyName = val.toUpperCase();
        }
        this.companyName = val;
      }
    },
    emailLower: {
      get: function () {
        if (this.email) {
          return this.email.toLowerCase();
        }
        return this.email;
      },
      set: function (val) {
        if (val) {
          this.email = val.toLowerCase();
        }
        this.email = val;
      }
    },
    lang() {
      return this.$lang;
    },
    dialogLabel() {
      if (this.companyId) {
        return this.lang.editCompany;
      } else {
        return this.lang.createCompany;
      }
    }
  }
}
</script>
