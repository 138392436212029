import store from '../../store';
import Vue from 'vue';
import httpLib from 'axios';
import {goToLogin} from '@/lib/redirects';

export const tkOptimizer = () => {
    return httpLib.create({
        baseURL: process.env.VUE_APP_TK_OPTIMIZER_BACKEND,
        withCredentials: true
    });
};

export const request = async (method, url, data = null, redirectLogin = true) => {
    let response = null;
    await method(url, data)
        .then((data) => (response = data.data))
        .catch(() => (store.dispatch('alert/showAlert', {
            message: Vue.prototype.$lang.error,
            timeout: 0
        })));
    if (redirectLogin) {
        if (response) {
            if (response.status === 401) {
                await store.dispatch('alert/showAlert', {
                    message: Vue.prototype.$lang.sessionExpired
                });
                response = null;
                goToLogin();
            }
        }
    }
    return response;
};

export const read = () => {
    return request(tkOptimizer().get, '', null, false);
}
