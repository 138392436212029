import {
    goToAccount,
    goToCompanies,
    goToHome,
    goToServiceOrders,
    goToTasksTechnicians,
    goToTechnicians
} from './redirects';
import Vue from 'vue';

export const home = () => {
    return {
        title: Vue.prototype.$lang.home,
        text: '',
        button: '',
        img: '',
        icon: 'mdi-home',
        click: () => {
            goToHome();
        }
    }
};

export const account = () => {
    return {
        title: Vue.prototype.$lang.account,
        text: Vue.prototype.$lang.accountDescription,
        button: Vue.prototype.$lang.accountBtnText,
        icon: 'mdi-account',
        img: 'user-profile.svg',
        click: () => {
            goToAccount();
        }
    }
};

export const technicians = () => {
    return {
        title: Vue.prototype.$lang.technicians,
        text: Vue.prototype.$lang.techniciansDescription,
        button: Vue.prototype.$lang.techniciansBtnText,
        icon: 'mdi-account-group',
        img: 'customer.svg',
        click: () => {
            goToTechnicians();
        }
    }
};

export const serviceOrders = () => {
    return {
        title: Vue.prototype.$lang.serviceOrders,
        text: Vue.prototype.$lang.serviceOrdersDescription,
        button: Vue.prototype.$lang.serviceOrdersBtnText,
        icon: 'mdi-file-document-edit',
        img: 'project.svg',
        click: () => {
            goToServiceOrders();
        }
    }
};

export const companies = () => {
    return {
        title: Vue.prototype.$lang.companies,
        text: Vue.prototype.$lang.companiesDescription,
        button: Vue.prototype.$lang.companiesBtnTxt,
        icon: 'mdi-domain',
        img: 'company.svg',
        click: () => {
            goToCompanies();
        }
    }
};

export const tasksTechnicians = () => {
    return {
        title: Vue.prototype.$lang.tasks,
        text: Vue.prototype.$lang.tasksDescription,
        button: Vue.prototype.$lang.tasksBtnTxt,
        icon: 'mdi-calendar-check',
        img: 'completed-task.svg',
        click: () => {
            goToTasksTechnicians();
        }
    }
};
