<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.tasks" action-menu :back-route="backPage"/>

    <dialog-form v-model="edit.dialog" :dialog-loading="edit.loading" :dialog-label="lang.editField" @ok="edit.save">
      <v-text-field
          slot="fields"
          ref="editField"
          v-model="edit.value"
          :type="edit.type"
          :label="edit.label"
          :error-messages="edit.messages"
          @input="edit.messages = []"
          validate-on-blur
      />
    </dialog-form>

    <v-main class="fill-height">
      <loader :loading="loading">
        <v-container slot="content">
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <div v-if="names.supervisor">
                    <span class="font-weight-black">{{ lang.supervisor }}: </span>
                    <span>{{ names.supervisor }}</span>
                  </div>
                  <div v-if="tasks.id">
                    <span class="font-weight-black">{{ lang.serviceOrder }}: </span>
                    <span>{{ tasks.service_order_id }}</span>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <router-link target="_blank" :to="{ name: 'serviceOrder', params: { id: tasks.service_order_id }}">
                    <v-btn color="primary">{{ lang.osInfo }}</v-btn>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="subtitle-1">
                  {{ titleTechnician1 }}
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-text>
                            <v-text-field
                                readonly
                                type="tel"
                                v-model.number="tasks.drop_start_length"
                                :label="lang.dropStartLength"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('drop_start_length', saveDecimalPositive, lang.dropStartLength, 'tel')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn @click="resetField('drop_start_length')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-text>
                            <v-text-field
                                readonly
                                type="tel"
                                v-model.number="tasks.drop_end_length"
                                :label="lang.dropEndLength"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('drop_end_length', saveDecimalPositive, lang.dropEndLength, 'tel')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn @click="resetField('drop_end_length')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.powerPowerMeterPicture }}</v-card-title>
                          <v-card-text>
                            <dynamic-picture :src-id="tasks.id" name="power-power-meter-picture"
                                             :img="tasks.power_power_meter_picture" tasks/>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="resetField('power_power_meter_picture')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.equipmentAccommodationPicture }}</v-card-title>
                          <v-card-text>
                            <dynamic-picture :src-id="tasks.id" name="equipment-accommodation-picture"
                                             :img="tasks.equipment_accommodation_picture" tasks/>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="resetField('equipment_accommodation_picture')" text small
                                   color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title v-if="names.technician2" class="subtitle-1">
                  {{ titleTechnician2 }}
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.powerDropCtoPortPicture }}</v-card-title>
                          <v-card-text>
                            <dynamic-picture :src-id="tasks.id" name="power-drop-cto-port-picture"
                                             :img="tasks.power_drop_cto_port_picture" tasks/>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="resetField('power_drop_cto_port_picture')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.ctoLocation }}</v-card-title>
                          <v-card-text>
                            <v-card class="ma-1">
                              <v-card-text>
                                <v-text-field
                                    readonly
                                    type="tel"
                                    v-model.number="tasks.latitude_cto"
                                    :label="lang.ctoLatitude"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-btn
                                    @click="openEditFields('latitude_cto', saveDecimal, lang.ctoLatitude, 'tel')"
                                    text small color="primary">
                                  {{ lang.editField }}
                                </v-btn>
                                <v-spacer/>
                                <v-btn @click="resetField('latitude_cto')" text small color="primary">
                                  {{ lang.requestResend }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                            <v-card class="ma-1">
                              <v-card-text>
                                <v-text-field
                                    readonly
                                    type="tel"
                                    v-model.number="tasks.longitude_cto"
                                    :label="lang.ctoLongitude"
                                />
                              </v-card-text>
                              <v-card-actions>
                                <v-btn
                                    @click="openEditFields('longitude_cto', saveDecimal, lang.ctoLongitude, 'tel')"
                                    text small color="primary">
                                  {{ lang.editField }}
                                </v-btn>
                                <v-spacer/>
                                <v-btn @click="resetField('longitude_cto')" text small color="primary">
                                  {{ lang.requestResend }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn target="_blank" :href="googleMapsCtoLink" text small color="secondary">
                              {{ lang.googleMaps }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-text>
                            <v-text-field
                                readonly
                                type="tel"
                                v-model="tasks.tag_cto"
                                :label="lang.tagCto"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('tag_cto', saveString, lang.tagCto, 'text')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn @click="resetField('tag_cto')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.accommodationCtoPicture }}</v-card-title>
                          <v-card-text>
                            <dynamic-picture :src-id="tasks.id" name="accommodation-cto-picture"
                                             :img="tasks.accommodation_cto_picture" tasks/>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="resetField('accommodation_cto_picture')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-card>
                          <v-card-title class="caption">{{ lang.contractPicture }}</v-card-title>
                          <v-card-text>
                            <dynamic-picture :src-id="tasks.id" name="contract-picture"
                                             :img="tasks.contract_picture"
                                             tasks/>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer/>
                            <v-btn @click="resetField('contract_picture')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-title class="subtitle-1">{{ lang.notes }}</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-card>
                          <v-card-text>
                            <v-textarea readonly v-model="tasks.supervisor_notes" color="primary"
                                        :label="lang.supervisor"/>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('supervisor_notes', saveString, lang.supervisorNotes, 'text')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col cols="12">
                        <v-card>
                          <v-card-text>
                            <v-textarea readonly v-model="tasks.technician_1_notes" color="primary"
                                        :label="lang.technician1"/>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('technician_1_notes', saveString, lang.tech1Notes, 'text')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn @click="resetField('technician_1_notes')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                      <v-col v-if="names.technician2" cols="12">
                        <v-card>
                          <v-card-text>
                            <v-textarea readonly v-model="tasks.technician_2_notes" color="primary"
                                        :label="lang.technician2"/>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                                @click="openEditFields('technician_2_notes', saveString, lang.tech2Notes, 'text')"
                                text small color="primary">
                              {{ lang.editField }}
                            </v-btn>
                            <v-spacer/>
                            <v-btn @click="resetField('technician_2_notes')" text small color="primary">
                              {{ lang.requestResend }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </loader>
    </v-main>

    <v-speed-dial v-model="fab" fab fixed bottom right open-on-hover>
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-dots-horizontal
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab dark small color="error"
                 @click="acceptTasks(serviceOrderStatus.cancelled)">
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>{{ lang.cancelOS }}</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab dark small color="warning"
                 @click="acceptTasks(serviceOrderStatus.unproductive)">
            <v-icon>mdi-alert-circle</v-icon>
          </v-btn>
        </template>
        <span>{{ lang.finishAsUnproductiveVisit }}</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab dark small color="secondary"
                 @click="acceptTasks(serviceOrderStatus.done)">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>{{ lang.finishOk }}</span>
      </v-tooltip>
    </v-speed-dial>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToServiceOrder, goToServiceOrders} from '@/lib/redirects';
import {getGoogleMapsLink, getServiceOrderStatus, ServiceOrderStatus} from '@/lib/util';
import {getNames, getTasksSupervisor, postFinishTasks, putSaveField} from '@/lib/backend/task';
import {mapActions} from 'vuex';
import DynamicPicture from '@/components/DynamicPicture';
import Loader from '@/components/Loader';
import DialogForm from '@/components/DialogForm';
import {validOnlyDecimal, validOnlyDecimalPositive} from '@/lib/validation';

export default {
  name: 'TasksSupervisor',
  components: {DialogForm, Loader, DynamicPicture, AppNavBar},
  data() {
    return {
      edit: {
        type: 'text',
        loading: false,
        messages: [],
        dialog: false,
        save: () => {
        },
        label: '',
        value: null
      },
      serviceOrderStatus: ServiceOrderStatus,
      fab: false,
      update: true,
      loading: false,
      names: {
        supervisor: null,
        technician1: null,
        technician2: null
      },
      tasks: {
        id: 0,
        service_order_id: 0,
        drop_start_length: null,
        power_drop_cto_port_picture: null,
        drop_end_length: null,
        latitude_cto: null,
        longitude_cto: null,
        accommodation_cto_picture: null,
        tag_cto: null,
        power_power_meter_picture: null,
        equipment_accommodation_picture: null,
        contract_picture: null,
        supervisor_notes: null,
        technician_1_notes: null,
        technician_2_notes: null
      },
      interval: null
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    this.loadTasksSupervisor();
    this.interval = setInterval(this.tasksInterval, 15000);
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    backPage() {
      if (this.tasks.service_order_id) {
        goToServiceOrder(this.tasks.service_order_id);
      } else {
        goToServiceOrders();
      }
    },
    async tasksInterval() {
      if (this.update) {
        const response = await getTasksSupervisor(this.tasks.id);
        if (response) {
          if (response.status === 200) {
            this.tasks = response.data;
          }
        }
      }
    },
    async loadTasksSupervisor() {
      this.loading = true;
      if (this.$route.params.id) {
        try {
          const id = parseInt(this.$route.params.id);
          if (id > 0) {
            const response = await getTasksSupervisor(id);
            if (response) {
              if (response.status === 200) {
                this.tasks = response.data;
                const name = await getNames(id);
                if (name) {
                  if (name.status === 200) {
                    this.names = name.data;
                    this.loading = false;
                    return;
                  }
                }
              }
            } else {
              return;
            }
          }
        } catch (e) {
          //
        }
      }

      this.showAlert({
        message: this.lang.openTasksError
      })
      this.loading = false;
      goToServiceOrders();
    },
    acceptTasks(status) {
      this.showConfirmation({
        title: this.lang.confirmQuestion,
        text: this.lang.finishTasks(this.tasks.service_order_id, getServiceOrderStatus(status)),
        action: async () => {
          this.update = false;
          this.loading = true;
          const response = await postFinishTasks(this.tasks.id, {
            service_order_status: status
          });
          if (response) {
            if (response.status === 200) {
              this.showAlert({message: this.lang.finishedTasks, timeout: 2000, color: 'success'});
              goToServiceOrders();
              return;
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.update = true;
          this.loading = false;
        }
      });
    },
    resetField(field) {
      this.showConfirmation({
        title: this.lang.confirmQuestion,
        text: this.lang.resetField,
        action: async () => {
          setTimeout(async () => {
            const response = await putSaveField(this.tasks.id, {
              field: field,
              data: null
            });
            if (response) {
              if (response.status === 200) {
                this.tasks[field] = null;
              }
            }
          }, 0);
        }
      });
    },
    async confirmationPutField(value) {
      this.showConfirmation({
        title: this.lang.confirmQuestion,
        text: this.lang.confirmationEditField,
        action: async () => {
          setTimeout(async () => {
            this.edit.loading = true;
            const response = await putSaveField(this.tasks.id, {
              field: this.edit.field,
              data: value
            });
            if (response) {
              if (response.status === 200) {
                this.tasks[this.edit.field] = value;
                this.edit.dialog = false;
              } else {
                this.showAlert({message: response.message});
              }
            }
            this.edit.loading = false;
          }, 0);
        }
      });
    },
    saveDecimalPositive() {
      this.saveDecimal(false);
    },
    saveDecimal(allowNegative = true) {
      const validator = allowNegative ? validOnlyDecimal : validOnlyDecimalPositive;
      if (validator(this.edit.value)) {
        try {
          const number = parseFloat(this.edit.value);
          if (!isNaN(number)) {
            this.confirmationPutField(number);
            return;
          }
        } catch (e) {
          //
        }
      }

      this.edit.messages = [allowNegative ? this.lang.onlyDecimalPositive : this.lang.onlyDecimal];
    },
    saveString() {
      if (this.edit.value) {
        this.confirmationPutField(this.edit.value);
        return;
      }
      this.edit.messages = [this.lang.invalidField];
    },
    openEditFields(field, save, label, type) {
      this.edit.type = type;
      this.edit.field = field;
      this.edit.save = save;
      this.edit.label = label;
      this.edit.value = this.tasks[field] ? this.tasks[field].toString() : this.tasks[field];
      this.edit.dialog = true;
      setTimeout(() => {
        this.$refs.editField.focus();
      }, 500);
    }
  },
  computed: {
    lang() {
      return this.$lang;
    },
    googleMapsCtoLink() {
      return getGoogleMapsLink(this.tasks.latitude_cto, this.tasks.longitude_cto);
    },
    titleTechnician1() {
      return this.names.technician1 ? `${this.lang.technician1}: ${this.names.technician1}` : this.lang.technician1;
    },
    titleTechnician2() {
      return this.names.technician2 ? `${this.lang.technician2}: ${this.names.technician2}` : this.titleTechnician1;
    }
  }
}
</script>
