<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.serviceOrders" action-menu :back-route="goToHome"/>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-expansion-panels class="pa-3" v-model="panels">
            <v-expansion-panel>
              <v-expansion-panel-header>{{ lang.queryTools }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card flat tile>
                  <v-card-text>
                    <v-container fluid class="pa-0">
                      <v-row>
                        <v-col cols="12" lg="4">
                          <v-card flat>
                            <v-card-title class="subtitle-1">{{ lang.schedule }}</v-card-title>
                            <v-card-text>
                              <v-radio-group v-model="radioSchedule">
                                <v-radio :label="lang.allDates" :value="1"/>
                                <v-radio :label="lang.noDatetime" :value="2"/>
                                <v-radio :label="lang.period" :value="3"/>
                              </v-radio-group>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col v-if="radioSchedule === 3" cols="12" lg="8">
                          <div class="caption">{{ lang.between }}</div>
                          <date-time-picker v-model="date1"/>
                          <div class="caption">{{ lang.and }}</div>
                          <date-time-picker v-model="date2"/>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" lg="2">
                          <v-select v-model="numberResults" :label="lang.numberResults" :items="numberResultsItems"/>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer/>
                    <v-btn @click="loadServiceOrders" color="primary">
                      <v-icon>mdi-magnify</v-icon>
                      {{ lang.search }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers"
                          :items="serviceOrders"
                          :loading="loading"
                          hide-default-footer
                          :items-per-page="itemsPerPage">
              <template v-slot:top>
                <div class="mb-5" v-if="serviceOrders.length">{{ lang.numberResults }}: {{ serviceOrders.length }}</div>
              </template>
              <template v-slot:item.status="{item}">
                {{ item.status | serviceOrderStatus }}
              </template>
              <template v-slot:item.schedule="{item}">
                {{ item.schedule | formatDate }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon @click="openServiceOrder(item)">mdi-eye</v-icon>
                <v-btn class="ml-3" target="_blank" :href="`${backend}/service-order/${item.id}/files`"
                       text x-small
                       fab
                       depressed
                       retain-focus-on-click>
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <v-btn class="mr-5 mb-5" color="primary" fab fixed bottom right @click="createServiceOrder">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToHome, goToServiceOrder} from '@/lib/redirects';
import {formatDate, getServiceOrderStatus} from '@/lib/util';
import {getServiceOrders, postServiceOrder} from '@/lib/backend/serviceOrders';
import {mapActions} from 'vuex';
import DateTimePicker from '@/components/DateTimePicker';

export default {
  name: 'ServiceOrders',
  components: {DateTimePicker, AppNavBar},
  data() {
    return {
      backend: process.env.VUE_APP_TK_OPTIMIZER_BACKEND,
      panels: 0,
      radioSchedule: 1,
      goToHome: goToHome,
      date1: null,
      date2: null,
      numberResultsItems: [5, 10, 20, 30, 50, 100],
      filter: 1,
      numberResults: 5,
      itemsPerPage: 5,
      headers: [
        {text: this.$lang.id, value: 'id'},
        {text: this.$lang.status, value: 'status'},
        {text: this.$lang.schedule, value: 'schedule'},
        {text: '', value: 'action', sortable: false}
      ],
      serviceOrders: [],
      loading: false
    }
  },
  filters: {
    serviceOrderStatus: getServiceOrderStatus,
    formatDate: formatDate
  },
  computed: {
    lang() {
      return this.$lang;
    }
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async loadServiceOrders() {
      const params = {n: this.numberResults};

      if (this.radioSchedule === 3) {
        if (!this.date1) {
          this.showAlert({message: this.lang.customFieldRequired(this.lang.between)});
          return;
        }

        if (!this.date2) {
          this.showAlert({message: this.lang.customFieldRequired(this.lang.and)});
          return;
        }

        const date1 = new Date(this.date1);
        const date2 = new Date(this.date2);

        if (date1 >= date2) {
          this.showAlert({message: this.lang.dateBetweenError});
          return;
        }

        params['d1'] = this.date1;
        params['d2'] = this.date2;
      } else if (this.radioSchedule === 2) {
        params['d1'] = 'null';
        params['d2'] = 'null';
      }

      this.loading = true;
      const response = await getServiceOrders(params);
      if (response) {
        if (response.status === 200) {
          this.itemsPerPage = this.numberResults;
          this.serviceOrders = response.data.serviceOrders;
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading = false;
      this.dialog = false;
      this.panels = null;
    },
    openServiceOrder(serviceOrder) {
      goToServiceOrder(serviceOrder.id);
    },
    createServiceOrder() {
      this.showConfirmation({
        title: this.lang.confirmQuestion,
        text: this.lang.createServiceOrder,
        action: async () => {
          this.loading = true;
          const response = await postServiceOrder();
          if (response) {
            if (response.status === 200) {
              this.openServiceOrder(response.data);
            } else {
              this.showAlert({message: response.message});
            }
          }
          this.loading = false;
        }
      });
    }
  }
}
</script>
