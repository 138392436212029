import Vue from 'vue';
import moment from 'moment';

export const ServiceOrderStatus = {
    waiting: 0,
    current: 1,
    done: 2,
    unproductive: 3,
    cancelled: 4
}

export const getServiceOrderStatus = status => {
    switch (status) {
        case ServiceOrderStatus.waiting:
            return Vue.prototype.$lang.waiting;
        case ServiceOrderStatus.current:
            return Vue.prototype.$lang.current;
        case ServiceOrderStatus.done:
            return Vue.prototype.$lang.done;
        case ServiceOrderStatus.unproductive:
            return Vue.prototype.$lang.unproductiveVisit;
        case ServiceOrderStatus.cancelled:
            return Vue.prototype.$lang.cancelled;
        default:
            return Vue.prototype.$lang.unknown;
    }
}

export const isWaitingServiceOrder = (status) => {
    return status === ServiceOrderStatus.waiting;
}

export const isDoneServiceOrder = (status) => {
    return status === ServiceOrderStatus.done;
}

export const isCurrentServiceOrder = (status) => {
    return status === ServiceOrderStatus.current;
}

export const getGoogleMapsLink = (latitude, longitude) => {
    if (latitude && longitude) {
        return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    } else {
        return 'https://www.google.com/maps';
    }
}

export const formatDate = date => {
    if (date) {
        const m = moment(new Date(date));
        return `${m.format("DD/MM/YYYY")} ${m.format("HH:mm")}`
    }
    return Vue.prototype.$lang.noDatetime;
}
