<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.tasks" action-menu :back-route="goToTasksTechnicians"/>
    <v-main class="fill-height">
      <loader :loading="loading.loader">
        <v-card slot="content">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      <div v-if="names.supervisor">
                        <span class="font-weight-black">{{ lang.supervisor }}: </span>
                        <span>{{ names.supervisor }}</span>
                      </div>
                      <div v-if="tasksBackend.service_order_id">
                        <span class="font-weight-black">{{ lang.serviceOrder }}: </span>
                        <span>{{ tasksBackend.service_order_id }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-show="isTechnician1 && tasksBackend.drop_start_length === null" cols="12">
                  <loader :loading="loading.drop_start_length">
                    <v-card slot="content">
                      <v-card-text>
                        <v-text-field
                            type="tel"
                            ref="drop_start_length"
                            v-model.number="tasks.drop_start_length"
                            :label="lang.dropStartLength"
                            :error-messages="messages.drop_start_length"
                            @input="messages.drop_start_length = []"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn v-if="!tasksBackend.drop_start_length" @click="sendNumberField('drop_start_length')" text
                               small
                               color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician2 && !tasksBackend.power_drop_cto_port_picture" cols="12">
                  <loader :loading="loading.power_drop_cto_port_picture">
                    <v-card slot="content">
                      <v-card-title class="caption">{{ lang.powerDropCtoPortPicture }}</v-card-title>
                      <v-card-text>
                        <loader :loading="imagesLoading.power_drop_cto_port_picture">
                          <input slot="content" type="file" id="power_drop_cto_port_picture" accept="image/*">
                        </loader>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendPictureField('power_drop_cto_port_picture')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician1 && tasksBackend.drop_end_length === null" cols="12">
                  <loader :loading="loading.drop_end_length">
                    <v-card slot="content">
                      <v-card-text>
                        <v-text-field
                            type="tel"
                            ref="drop_end_length"
                            v-model.number="tasks.drop_end_length"
                            :label="lang.dropEndLength"
                            :error-messages="messages.drop_end_length"
                            @input="messages.drop_end_length = []"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendNumberField('drop_end_length')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col
                    v-show="isTechnician2 && (tasksBackend.latitude_cto === null || tasksBackend.longitude_cto === null)"
                    cols="12">
                  <loader :loading="loading.latitude_cto || loading.longitude_cto">
                    <v-card slot="content">
                      <v-card-text>
                        <div v-show="tasksBackend.latitude_cto === null">
                          <v-text-field
                              type="tel"
                              ref="latitude_cto"
                              v-model.number="tasks.latitude_cto"
                              :label="lang.ctoLatitude"
                              :error-messages="messages.latitude_cto"
                              @input="messages.latitude_cto = []"
                          />
                          <v-btn @click="sendNumberField('latitude_cto', true)" text small color="primary">
                            {{ lang.sendField }}
                          </v-btn>
                        </div>
                        <div v-show="tasksBackend.longitude_cto === null">
                          <v-text-field
                              type="tel"
                              ref="longitude_cto"
                              v-model.number="tasks.longitude_cto"
                              :label="lang.ctoLongitude"
                              :error-messages="messages.longitude_cto"
                              @input="messages.longitude_cto = []"
                          />
                          <v-btn @click="sendNumberField('longitude_cto', true)" text small color="primary">
                            {{ lang.sendField }}
                          </v-btn>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text small color="secondary" @click="fillLocation">
                          {{ lang.fillLocation }}
                        </v-btn>
                        <v-spacer/>
                        <v-btn target="_blank" :href="googleMapsCtoLink" text small color="secondary">
                          {{ lang.googleMaps }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician2 && !tasksBackend.tag_cto" cols="12">
                  <loader :loading="loading.tag_cto">
                    <v-card slot="content">
                      <v-card-text>
                        <v-text-field
                            type="tel"
                            v-model="tasks.tag_cto"
                            :label="lang.tagCto"
                            :error-messages="messages.tag_cto"
                            @input="messages.tag_cto = []"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendAlphaNumericField('tag_cto')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician2 && !tasksBackend.accommodation_cto_picture" cols="12">
                  <loader :loading="loading.accommodation_cto_picture">
                    <v-card slot="content">
                      <v-card-title class="caption">{{ lang.accommodationCtoPicture }}</v-card-title>
                      <v-card-text>
                        <loader :loading="imagesLoading.accommodation_cto_picture">
                          <input slot="content" type="file" id="accommodation_cto_picture" accept="image/*">
                        </loader>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendPictureField('accommodation_cto_picture')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician1 && !tasksBackend.power_power_meter_picture" cols="12">
                  <loader :loading="loading.power_power_meter_picture">
                    <v-card slot="content">
                      <v-card-title class="caption">{{ lang.powerPowerMeterPicture }}</v-card-title>
                      <v-card-text>
                        <loader :loading="imagesLoading.power_power_meter_picture">
                          <input slot="content" type="file" id="power_power_meter_picture" accept="image/*">
                        </loader>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendPictureField('power_power_meter_picture')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician1 && !tasksBackend.equipment_accommodation_picture" cols="12">
                  <loader :loading="loading.equipment_accommodation_picture">
                    <v-card slot="content">
                      <v-card-title class="caption">{{ lang.equipmentAccommodationPicture }}</v-card-title>
                      <v-card-text>
                        <loader :loading="imagesLoading.equipment_accommodation_picture">
                          <input slot="content" type="file" id="equipment_accommodation_picture" accept="image/*">
                        </loader>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendPictureField('equipment_accommodation_picture')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician2 && !tasksBackend.contract_picture" cols="12">
                  <loader :loading="loading.contract_picture">
                    <v-card slot="content">
                      <v-card-title class="caption">{{ lang.contractPicture }}</v-card-title>
                      <v-card-text>
                        <loader :loading="imagesLoading.contract_picture">
                          <input slot="content" type="file" id="contract_picture" accept="image/*">
                        </loader>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendPictureField('contract_picture')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-show="isTechnician1 && !tasksBackend.technician_1_notes" cols="12">
                  <loader :loading="loading.technician_1_notes">
                    <v-card slot="content">
                      <v-card-text>
                        <v-textarea v-model="tasks.technician_1_notes" color="primary" :label="lang.notes"
                                    :error-messages="messages.technician_1_notes = []"/>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendNullableField('technician_1_notes')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
                <v-col v-show="isTechnician2 && names.technician2 && !tasksBackend.technician_2_notes" cols="12">
                  <loader :loading="loading.technician_2_notes">
                    <v-card slot="content">
                      <v-card-text>
                        <v-textarea v-model="tasks.technician_2_notes" color="primary" :label="lang.notes"
                                    :error-messages="messages.technician_2_notes = []"/>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer/>
                        <v-btn @click="sendNullableField('technician_2_notes')" text small color="primary">
                          {{ lang.sendField }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </loader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-text>
                      {{ lang.waitingSupervisor }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </loader>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToTasksTechnicians} from '@/lib/redirects';
import Loader from '@/components/Loader';
import {mapActions} from 'vuex';
import {getGoogleMapsLink} from '@/lib/util';
import {getNames, getTasksTechniciansFields, putTasksTechniciansSaveField} from '@/lib/backend/task';
import Compressor from 'compressorjs';
import {validOnlyDecimal, validOnlyDecimalPositive} from "@/lib/validation";

export default {
  name: 'TasksTechnician',
  components: {AppNavBar, Loader},
  data() {
    return {
      imagesLoaded: {
        power_drop_cto_port_picture: null,
        accommodation_cto_picture: null,
        power_power_meter_picture: null,
        equipment_accommodation_picture: null,
        contract_picture: null
      },
      imagesIds: [
        'power_drop_cto_port_picture',
        'accommodation_cto_picture',
        'power_power_meter_picture',
        'equipment_accommodation_picture',
        'contract_picture'
      ],
      imagesLoading: {
        power_drop_cto_port_picture: false,
        accommodation_cto_picture: false,
        power_power_meter_picture: false,
        equipment_accommodation_picture: false,
        contract_picture: false
      },
      messages: {
        drop_start_length: [],
        drop_end_length: [],
        latitude_cto: [],
        longitude_cto: [],
        tag_cto: [],
        technician_1_notes: [],
        technician_2_notes: []
      },
      loading: {
        loader: false,
        drop_start_length: false,
        power_drop_cto_port_picture: false,
        drop_end_length: false,
        latitude_cto: false,
        longitude_cto: false,
        accommodation_cto_picture: false,
        tag_cto: false,
        power_power_meter_picture: false,
        equipment_accommodation_picture: false,
        contract_picture: false,
        technician_1_notes: false,
        technician_2_notes: false
      },
      tasks: {
        id: 0,
        service_order_id: null,
        service_order_type: null,
        drop_start_length: null,
        power_drop_cto_port_picture: null,
        drop_end_length: null,
        latitude_cto: null,
        longitude_cto: null,
        accommodation_cto_picture: null,
        tag_cto: null,
        power_power_meter_picture: null,
        equipment_accommodation_picture: null,
        contract_picture: null,
        technician_1_notes: null,
        technician_2_notes: null
      },
      tasksBackend: {
        id: 0,
        service_order_id: null,
        service_order_type: null,
        drop_start_length: null,
        power_drop_cto_port_picture: null,
        drop_end_length: null,
        latitude_cto: null,
        longitude_cto: null,
        accommodation_cto_picture: null,
        tag_cto: null,
        power_power_meter_picture: null,
        equipment_accommodation_picture: null,
        contract_picture: null,
        technician_1_notes: null,
        technician_2_notes: null
      },
      goToTasksTechnicians: goToTasksTechnicians,
      role: null,
      interval: null,
      names: {
        supervisor: null,
        technician1: null,
        technician2: null
      }
    }
  },
  mounted() {
    this.start();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions('confirmation', ['showConfirmation']),
    ...mapActions('alert', ['showAlert']),
    async start() {
      const roleParam = this.$route.params.role;
      if (roleParam === 'technician1' || roleParam === 'technician2') {
        this.role = roleParam;
        await this.loadTasksTechnicians();
        const name = await getNames(this.tasksBackend.id);
        if (name) {
          if (name.status === 200) {
            this.names = name.data;
            this.interval = setInterval(this.tasksInterval, 15000);
            const ctx = this;
            this.imagesIds.map(id => {
              const el = document.getElementById(id);
              if (el) {
                el.addEventListener('change', (e) => {
                  ctx.imagesLoading[id] = true;
                  const file = e.target.files[0];

                  if (!file) {
                    return;
                  }

                  new Compressor(file, {
                    quality: 0.6,
                    convertSize: 1000,
                    success(result) {
                      const reader = new FileReader();
                      reader.readAsDataURL(result);
                      reader.onloadend = function () {
                        ctx.imagesLoaded[id] = reader.result;
                        ctx.imagesLoading[id] = false;
                      }
                    },
                    error(err) {
                      console.log(err);
                      ctx.showAlert({message: ctx.lang.imageError});
                      ctx.imagesLoading[id] = false;
                    },
                  });
                });
              }
            });
            return;
          }
        }
      }
      goToTasksTechnicians();
    },
    async tasksInterval() {
      if (this.tasksBackend.id) {
        const response = await getTasksTechniciansFields(this.tasksBackend.id);
        if (response) {
          if (response.status === 200) {
            this.tasksBackend = response.data;
          } else if (response.status === 404) {
            this.showAlert({message: this.lang.finishedTasks, timeout: 2000, color: 'success'});
            goToTasksTechnicians();
          } else {
            this.showAlert({message: response.message});
          }
        }
      }
    },
    async loadTasksTechnicians() {
      this.loading.loader = true;
      if (this.$route.params.id) {
        try {
          const id = parseInt(this.$route.params.id);
          if (id > 0) {
            const response = await getTasksTechniciansFields(id);
            if (response) {
              if (response.status === 200) {
                this.tasksBackend = response.data;
                this.tasks.technician_1_notes = response.data.technician_1_notes;
                this.tasks.technician_2_notes = response.data.technician_2_notes;
                this.loading.loader = false;
                return;
              }
            } else {
              return;
            }
          }
        } catch (e) {
          //
        }
      }

      this.showAlert({
        message: this.lang.openTasksError
      })
      this.loading.loader = false;
    },
    sendNullableField(field) {
      if (this.tasks[field]) {
        this.sendField(field);
      } else {
        this.messages[field] = [this.lang.invalidField];
      }
    },
    sendPictureField(field) {
      if (!this.imagesLoading[field]) {
        if (this.imagesLoaded[field]) {
          this.tasks[field] = this.imagesLoaded[field];
          this.sendField(field);
        } else {
          this.showAlert({message: this.lang.imageNotLoaded});
        }
      }
    },
    sendAlphaNumericField(field) {
      if (this.tasks[field]) {
        this.showConfirmation({
          title: this.lang.confirmQuestion,
          text: this.lang.sendCustomValue(this.tasks[field]),
          action: () => {
            this.sendField(field);
          }
        });
      } else {
        this.messages[field] = [this.lang.invalidField];
      }
    },
    sendNumberField(field, allowNegative = false) {
      if (this.$refs[field].internalValue !== null && this.$refs[field].internalValue !== undefined) {
        const validator = allowNegative ? validOnlyDecimal : validOnlyDecimalPositive;
        if (validator(this.$refs[field].internalValue.toString())) {
          this.showConfirmation({
            title: this.lang.confirmQuestion,
            text: this.lang.sendCustomValue(this.tasks[field]),
            action: () => {
              this.sendField(field);
            }
          });
          return;
        }
      }
      this.messages[field] = [this.lang.invalidField];
    },
    async sendField(field) {
      this.loading[field] = true;
      const response = await putTasksTechniciansSaveField(this.tasksBackend.id, this.role, {
        field: field,
        data: this.tasks[field]
      });
      if (response) {
        if (response.status === 200) {
          this.tasksBackend[field] = this.tasks[field];
        } else if (response.status === 403) {
          //
        } else {
          this.showAlert({message: response.message});
        }
      }
      this.loading[field] = false;
    },
    fillLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          this.tasks.latitude_cto = position.coords.latitude;
          this.tasks.longitude_cto = position.coords.longitude;
        }, error => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.showAlert({message: "User denied the request for Geolocation."});
              break;
            case error.POSITION_UNAVAILABLE:
              this.showAlert({message: "Location information is unavailable."});
              break;
            case error.TIMEOUT:
              this.showAlert({message: "The request to get user location timed out."});
              break;
            default:
              this.showAlert({message: "An unknown error occurred."});
              break;
          }
        });
      } else {
        this.showAlert({message: this.lang.unsupportedLocation});
      }
    }
  },
  computed: {
    isTechnician1() {
      return this.role === 'technician1';
    },
    isTechnician2() {
      return this.role === 'technician2' || !this.names.technician2;
    },
    lang() {
      return this.$lang
    },
    googleMapsCtoLink() {
      return getGoogleMapsLink(this.tasks.latitude_cto, this.tasks.longitude_cto);
    }
  }
}
</script>
