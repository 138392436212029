<template>
  <loader :loading="loading">
    <viewer slot="content" :images="images" class="viewer viewer-2">
      <template>
        <img v-if="image" :src="image" :key="image" alt="image"/>
        <div class="text-center" v-else>{{ lang.noPicture }}</div>
      </template>
    </viewer>
  </loader>
</template>

<script>
import {getTasksImage} from '@/lib/backend/task';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer/src/component.vue';
import Loader from '@/components/Loader';
import {getServiceOrderImage} from "@/lib/backend/serviceOrders";

export default {
  props: {
    srcId: Number,
    img: String,
    name: String,
    tasks: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {Loader, Viewer},
  name: 'DynamicPicture',
  data() {
    return {
      image: null,
      loading: false
    }
  },
  mounted() {
    this.loadPicture();
  },
  methods: {
    async loadPicture() {
      if (this.img) {
        this.loading = true;
        const method = this.tasks ? getTasksImage : getServiceOrderImage;
        const response = await method(this.srcId, this.name);
        if (response) {
          if (response.status === 200) {
            if (response.data) {
              this.image = response.data;
            }
          }
        }
        this.loading = false;
      }
    }
  },
  computed: {
    lang() {
      return this.$lang;
    },
    images() {
      return [this.image];
    }
  },
  watch: {
    img: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.loadPicture();
        } else {
          this.image = null;
        }
      }
    }
  }
}
</script>

<style>
.viewer-2 img {
  cursor: pointer;
  height: 100%;
  width: 100%;
}
</style>
