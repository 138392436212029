<template>
  <div class="fill-height" v-if="user.role === 'user'">
    <home-default :title="lang.userPanel" :items="[account]"/>
  </div>
  <div class="fill-height" v-else-if="user.role === 'supervisor'">
    <home-default :title="lang.supervisorPanel" :items="[technicians, serviceOrders, companies, account]"/>
  </div>
  <div class="fill-height" v-else-if="user.role === 'technician'">
    <home-default :title="lang.technicianPanel" :items="[account, tasksTechnicians]"/>
  </div>
  <div v-else class="display-1">{{ lang.error }}</div>
</template>

<script>
import HomeDefault from './home/HomeDefault';
import {mapState} from 'vuex';
import {account, companies, serviceOrders, tasksTechnicians, technicians} from '@/lib/menu';

export default {
  name: 'Home',
  components: {HomeDefault},
  data() {
    return {
      account: account(),
      technicians: technicians(),
      serviceOrders: serviceOrders(),
      companies: companies(),
      tasksTechnicians: tasksTechnicians()
    }
  },
  computed: {
    ...mapState({
      user: state => state.config.user
    }),
    lang() {
      return this.$lang;
    }
  }
}
</script>
