<template>
  <div class="fill-height">
    <app-nav-bar :title-bar="lang.tasks" action-menu :back-route="goToHome"/>

    <v-dialog v-model="dialog">
      <v-card>
        <v-card-text>
          <v-data-table :headers="headersSeeMore" :items="itemsSeeMore" disable-sort hide-default-footer>
            <template v-slot:item.key="{item}">
              <span class="font-weight-black">{{ item.key }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" text @click="dialog = false">{{ lang.close }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="fill-height">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="tasksTechnicians" :loading="loading" :search="search"
                          disable-sort
                          hide-default-footer>
              <template v-slot:item.schedule="{item}">
                {{ item.schedule | formatDate }}
              </template>
              <template v-slot:item.action="{item}">
                <v-btn v-if="location(item)" :href="location(item)" target="_blank" text>
                  <v-icon>mdi-google-maps</v-icon>
                </v-btn>
                <v-btn @click="seeMore(item)" text>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.action2="{item}">
                <v-btn v-if="item.tasks" @click="goToTasks(item)" color="primary">
                  {{ lang.goToTasks }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import AppNavBar from '@/components/AppNavBar';
import {goToHome, goToTasksTechnician} from '@/lib/redirects';
import {getTasksTechnicians} from '@/lib/backend/tasksTechnicians';
import {mapActions} from 'vuex';
import {formatDate, getGoogleMapsLink} from '@/lib/util';

export default {
  name: 'TasksTechnicians',
  components: {AppNavBar},
  data() {
    return {
      headersSeeMore: [
        {text: '', value: 'key', sortable: false},
        {text: '', value: 'value', sortable: false}
      ],
      goToHome: goToHome,
      dialog: false,
      search: '',
      loading: false,
      tasksTechnicians: [],
      headers: [
        {text: this.$lang.serviceOrder, value: 'service_order_id'},
        {text: this.$lang.datetime, value: 'schedule'},
        {text: '', value: 'action', sortable: false},
        {text: '', value: 'action2', sortable: false}
      ],
      info: {
        client_name: null,
        client_number_id: null,
        client_phone: null,
        client_address: null,
        company_name: null,
        latitude: null,
        longitude: null,
        schedule: null,
        service_order_id: null,
        tasks: null
      },
      interval: null
    }
  },
  mounted() {
    this.loading = true;
    this.loadTechniciansTasks();
    this.loading = false;
    this.interval = setInterval(this.loadTechniciansTasks, 15000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  filters: {
    formatDate: formatDate
  },
  computed: {
    lang() {
      return this.$lang;
    },
    itemsSeeMore() {
      return [
        {
          key: this.lang.clientName,
          value: this.info.client_name || this.lang.noInfo
        },
        {
          key: this.lang.clientAddress,
          value: this.info.client_address || this.lang.noInfo
        },
        {
          key: this.lang.numberOrOthers,
          value: this.info.client_number_id || this.lang.noInfo
        },
        {
          key: this.lang.phone,
          value: this.info.client_phone || this.lang.noInfo
        },
        {
          key: this.lang.company,
          value: this.info.company_name || this.lang.noInfo
        },
        {
          key: this.lang.datetime,
          value: formatDate(this.info.schedule)
        },
        {
          key: this.lang.serviceOrder,
          value: this.info.service_order_id || this.lang.noInfo
        }
      ];
    }
  },
  methods: {
    ...mapActions('alert', ['showAlert']),
    location(item) {
      if (item.longitude && item.latitude) {
        return getGoogleMapsLink(item.latitude, item.longitude);
      } else {
        return null;
      }
    },
    async loadTechniciansTasks() {
      const response = await getTasksTechnicians();
      if (response) {
        if (response.status === 200) {
          this.tasksTechnicians = response.data;
        } else {
          this.showAlert({message: response.message});
        }
      }
    },
    seeMore(item) {
      this.info = item;
      this.dialog = true;
    },
    goToTasks(item) {
      goToTasksTechnician(item.tasks, item.role);
    }
  }
}
</script>
