export default {
    label: 'Portugês do Brasil',
    version: 'Versão',
    logout: 'Sair',
    saved: 'Salvo',
    error: 'Ocorreu um erro. Tente novamente mais tarde.',
    makeLogin: 'Fazer login',
    makeLoginInstead: 'Fazer login',
    home: 'Inicial',
    projects: 'Projetos',
    projectsDescription: 'Gerencie seus projetos, acompanhe os status, etc.',
    projectsBtnText: 'Gerenciar seus projetos',
    account: 'Conta',
    technicians: 'Técnicos',
    techniciansDescription: 'Realize as atribuições dos técnicos de acordo com o seu perfil.',
    techniciansBtnText: 'Gerenciar técnicos',
    accountDescription: 'Realize alterações de senha, nome e contatos.',
    accountBtnText: 'Gerenciar sua conta',
    hello: 'Olá',
    cancel: 'Cancelar',
    concluded: 'Concluído',
    email: 'E-mail',
    edit: 'Editar',
    delete: 'Excluir',
    permissions: 'Permissões',
    deleteProject: 'Excluir projeto',
    password: 'Senha',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    confirmPassword: 'Confirme a senha',
    confirmNewPassword: 'Confirme nova senha',
    cellphone: 'Celular',
    landLine: 'Fixo',
    phone: 'Telefone',
    address: 'Endereço',
    additionalInfo: 'Informações adicionais',
    addPhone: 'Adicionar telefone',
    passwordChangedAndDoLoginAgain: 'Senha alterada. Faça o login novamente para continuar.',
    fieldRequired: 'Este campo é obrigatório',
    invalidField: 'Campo inválido',
    invalidCnpj: 'CNPJ inválido',
    invalidPassword: 'Senha inválida',
    sameOldNewPassword: 'A nova senha não pode ser a mesma da antiga senha',
    notEqualConfirmPasswordNewPassword: 'As senhas não conferem',
    invalidEmail: 'E-mail inválido',
    invalidPhone: 'Telefone inválido',
    confirmQuestion: 'Confirmar?',
    userPanel: 'Painel do usuário',
    supervisorPanel: 'Painel do supervisor',
    technicianPanel: 'Painel do técnico',
    companyPanel: 'Painel da empresa',
    personPhysical: 'Pessoa física',
    personJuridical: 'Pessoa jurídica',
    createAccount: 'Criar conta',
    signIn: 'Entrar',
    tooManyAttemptsTryLater: 'Muitas tentativas não autorizadas. Tente novamente mais tarde.',
    accountCreatedDoLogin: 'Conta criada com sucesso! Entre no sistema para continuar.',
    id: 'ID',
    name: 'Nome',
    owner: 'Proprietário',
    newProject: 'Novo projeto',
    updateProjects: 'Atualizar lista de projetos',
    search: 'Buscar',
    open: 'Abrir',
    openProject: 'Abrir projeto',
    noProjects: 'Não há projetos',
    loadingProjects: 'Carregando projetos...',
    noProjectFound: 'Nenhum projeto encontrado',
    createdAt: 'Criado em',
    updatedAt: 'Atualizado em',
    editProject: 'Editar projeto',
    no: 'Não',
    yes: 'Sim',
    close: 'Fechar',
    unknown: 'Desconhecido',
    loading: 'Carregando...',
    invalidProject: 'Projeto inválido',
    protocols: 'Protocolos',
    submitProject: 'Enviar para análise',
    permissionsProject: 'Permissões do projeto',
    users: 'Usuários',
    changeProjectName: 'Alterar nome do projeto',
    financesReport: 'Relatório financeiro',
    financesReportDescription: 'Visualize suas faturas abertas, pagas e vencidas.',
    financesBtnText: 'Visualizar finanças',
    backProjects: 'Voltar para projetos',
    backHome: 'Voltar para página inicial',
    yourProjects: 'Seus projetos',
    otherProjects: 'Outros projetos',
    contracts: 'Contratos',
    contractsDescription: 'Gerencie os status dos seus contratos.',
    contractsBtnText: 'Gerenciar contratos',
    networkMap: 'Mapa da rede',
    networkMapDescription: 'Visualize o mapa completo da sua rede de todos os projetos.',
    networkMapBtnText: 'Visualizar mapa',
    addPermission: 'Adicionar usuário',
    noPermission: 'Nenhum usuário',
    noPhone: 'Nenhum telefone',
    userAlreadyPermission: 'O usuário informado já está na lista de permissões',
    userOwnerProject: 'O e-mail informado pertence ao proprietário do projeto',
    noAccountAssociated: 'Não há conta associada à este email',
    accountAssociated: 'Já existe uma conta associada à este email',
    onlyRead: 'Apenas visualizar',
    saveChanges: 'Salvar alterações',
    dragEditPost: 'Arraste o poste para posicioná-lo',
    actions: 'Ações',
    none: 'Nenhum',
    technician1: 'Técnico 1',
    technician2: 'Técnico 2',
    role: 'Função',
    serviceOrders: 'Ordens de Serviço',
    serviceOrdersDescription: 'Crie, remova e gerencie as ordens de serviço dentro do sistema.',
    serviceOrdersBtnText: 'Gerenciar ordens',
    companies: 'Empresas',
    companiesDescription: 'Crie, remova e gerencie as ISPs.',
    companiesBtnTxt: 'Gerenciar empresas',
    cnpj: 'CNPJ',
    createCompany: 'Cadastrar empresa',
    editCompany: 'Editar empresa',
    schedule: 'Agendamento',
    tasks: 'Tarefas',
    status: 'Status',
    supervisor: 'Supervisor',
    location: 'Localização',
    company: 'Empresa',
    waiting: 'Aguardando',
    current: 'Em andamento',
    rescheduled: 'Reagendado',
    done: 'Concluído',
    clientNumberId: 'Número de identificação do cliente (CPF, UUID ou outros)',
    latitudeClient: 'Latitude do cliente',
    longitudeClient: 'Longitude do cliente',
    googleMaps: 'Abrir no Google Maps',
    notes: 'Anotações',
    startTime: 'Horário inicial',
    endTime: 'Horário final',
    scheduleNew: 'Novo agendamento',
    type: 'Tipo',
    dropStartLength: 'Comprimento inicial do drop (m)',
    dropEndLength: 'Comprimento final do drop (m)',
    ctoLatitude: 'Latitude da CTO',
    ctoLongitude: 'Longitude da CTO',
    openToOnlineTasks: 'Abrir OS para tarefas online',
    osInfo: 'Informações da OS',
    date: 'Data',
    time: 'Hora',
    clearDate: 'Limpar data',
    clearTime: 'Limpar hora',
    contractPicture: 'Foto do contrato assinado',
    equipmentAccommodationPicture: 'Foto da acomodação do equipamento',
    powerPowerMeterPicture: 'Foto da potência da casa do cliente (PowerMeter)',
    tagCto: 'Tag da CTO',
    accommodationCtoPicture: 'Foto da acomodação da CTO',
    powerDropCtoPortPicture: 'Foto da potência na porta da CTO',
    openTasks: 'Abrir OS para tarefas',
    saveOs: 'Salvar OS',
    noDatetime: 'Sem data/hora definida',
    createServiceOrder: 'Uma nova ordem de serviço será gerada',
    serviceOrderIdError: 'Ordem de serviço inválida',
    openTasksWarning: 'A OS entrará no modo "em andamento"',
    deleteOs: 'Remover OS',
    openTasksError: 'Houve um erro na inicialização das tarefas',
    clientName: 'Nome do cliente',
    clientPhone: 'Telefone do cliente',
    clientAddress: 'Endereço do cliente',
    goToTasks: 'Ir para tarefas',
    noPicture: 'Sem imagem',
    requestResend: 'Solicitar reenvio',
    resetField: 'O campo será colocado como vazio e o técnico terá que fazer o reenvio',
    warningEmptyCompanies: 'Não é possível continuar pois não há nenhuma empresa cadastrada',
    warningEmptyTechs: 'Não é possível continuar pois não há nenhum técnico cadastrado',
    tasksDescription: 'Verifique suas tarefas agendadas e trabalhe sob a orientação de um supervisor.',
    tasksBtnTxt: 'Ir para tarefas',
    serviceOrder: 'Ordem de serviço',
    noInfo: 'Não informado',
    numberOrOthers: 'CPF, UUID ou outros',
    sendField: 'Enviar campo',
    imageError: 'Erro ao carregar a imagem',
    imageSendError: 'Erro ao enviar imagem',
    fillLocation: 'Localização',
    unsupportedLocation: 'Seu navegador não suporta localizações',
    waitingSupervisor: 'Aguarde o supervisor confirmar todos os campos e encerrar as tarefas. Ele poderá solicitar que você reenvie alguns campos. Quando as tarefas terminarem você será redirecionado automaticamente.',
    notAllowedRoute: 'Área não permitida para o seu usuário',
    sessionExpired: 'Sessão expirada. Faça o login para continuar.',
    onlyNumbers: 'Somente números',
    pasteCoordinates: 'Colar coordenadas',
    fullLatitudeLongitude: 'Latitude,Longitude',
    errorPasteCoordinates: 'As coordenadas não foram identificadas',
    unproductiveVisit: 'Visita improdutiva',
    cancelled: 'Cancelada',
    technician: 'Técnico',
    osType: 'Tipo',
    osTypeInstallation: 'Instalação',
    osTypeMaintenance: 'Manutenção',
    osTypeTechnicalVisit: 'Visita técnica',
    duplicatedTechnicians: 'Selecione técnicos diferentes',
    finishAsUnproductiveVisit: 'Finalizar como visita improdutiva',
    cancelOS: 'Cancelar OS',
    finishOk: 'Finalizar como concluído',
    viewOs: 'Ver OS',
    datetime: 'Horário',
    ctoLocation: 'Localização da CTO',
    imageNotLoaded: 'Arquivo de foto não selecionado',
    finishedTasks: 'Tarefas finalizadas',
    editField: 'Editar campo',
    onlyDecimalPositive: 'Este campo aceita apenas decimais positivos',
    onlyDecimal: 'Este campo aceita apenas decimais (positivos ou negativos)',
    confirmationEditField: 'Este valor substituirá o valor enviado previamente por qualquer pessoa',
    supervisorNotes: 'Notas do supervisor',
    tech1Notes: 'Notas do técnico 1',
    tech2Notes: 'Notas do técnico 2',
    numberResults: 'Número de resultados',
    queryTools: 'Ferramenta de busca',
    timeStart: 'Hora da abertura',
    timeEnd: 'Hora do fechamento',
    between: 'Entre',
    and: 'E',
    dateBetweenError: 'A data "Entre" não pode ser maior ou igual a data "E"',
    allDates: 'Todas as datas',
    period: 'Períodos específicos',
    ///////////////////////////////////////////////////////////////////////////////////////////////
    sendCustomValue: field => `O valor ${field.toString()} será enviado`,
    finishTasks: (serviceOrderId, status) => `Todos os campos informados aqui serão transferidos para a ordem de serviço ${serviceOrderId} e a mesma será finalizada com status "${status}". A operação é irreversível.`,
    customFieldRequired: field => `O campo ${field} não pode ser vazio`,
    customInvalidLengthPassword: len => `A nova senha deve ter no mínimo ${len} caracteres`,
    customDelete: val => `Excluir ${val}`,
    customServiceOrder: number => `Ordem de serviço ${number}`,
};
