import {request, tkOptimizer} from '@/lib/backend/http';

export const postTasks = (inputCreateTasks) => {
    return request(tkOptimizer().post, '/tasks', inputCreateTasks);
};

export const getTasksSupervisor = id => {
    return request(tkOptimizer().get, `/tasks/${id}/supervisor`);
}

export const getTasksImage = (tasksId, image) => {
    return request(tkOptimizer().get, `/tasks/${tasksId}/${image}`);
}

export const putSaveField = (tasksId, field) => {
    return request(tkOptimizer().put, `/tasks/${tasksId}/save-field`, field);
}

export const postFinishTasks = (tasksId, data) => {
    return request(tkOptimizer().post, `/tasks/${tasksId}/finish`, data);
};

export const putTasksTechniciansSaveField = (tasksId, role, data) => {
    return request(tkOptimizer().put, `/tasks/${tasksId}/${role}/save-field`, data);
}

export const getTasksTechniciansFields = id => {
    return request(tkOptimizer().get, `/tasks/${id}/technicians`);
}

export const getNames = id => {
    return request(tkOptimizer().get, `/tasks/${id}/names`);
}
